//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-404:_2316,_9136,_6708,_8004,_4584,_5780,_992,_452;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-404')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-404', "_2316,_9136,_6708,_8004,_4584,_5780,_992,_452");
        }
      }catch (ex) {
        console.error(ex);
      }